<template>
  <div>
    <a-modal
      v-model="visibleModalManagePengajuanIzin"
      class="mod"
      :title="status.isEdit ? 'Form Detail Pengajuan Izin' : 'Form Tambah Pengajuan Izin Izin'"
      @cancel="toggleModalManagePengajuanIzin"
      centered
    >
      <form-pengajuan-izin
        :disabled="true"
        :dataPengajuanIzin="newPengajuanIzin"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button class="d-none" size="large" key="back" @click="toggleModalManagePengajuanIzin">
          Batal
        </a-button>
        <a-button class="d-none" size="large" key="submit" type="primary" :loading="loadingActionModalManagePengajuanIzin" @click="handleOkModalManagePengajuanIzin">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalManageKeteranganAdminPengajuanIzin"
      title="Form Penolakan Persetujuan Izin"
      @cancel="toggleModalManageKeteranganAdminPengajuanIzin"
      centered
    >
      <a-row>
        <a-col :span="24">
        <a-form-item label="Keterangan Admin HR">
          <a-textarea
            v-model="newPengajuanIzin.keterangan_admin"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
      </a-row>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageKeteranganAdminPengajuanIzin">
          Batal
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageKeteranganAdminPengajuanIzin" @click="handleApprovalPengajuanIzin('reject', newPengajuanIzin)">
          Tolak Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <!-- <div class="ml-auto">
        <a-button @click.prevent="toggleModalManagePengajuanIzin('add')" size="large" type="primary"><a-icon type="plus" />Tambah Izin</a-button>
      </div> -->
    </div>
    <div class="mt-4">
      <a-table
        :columns="pengajuanIzinColumns"
        :data-source="pengajuanIzinDataTable"
        :pagination="pagination"
        :loading="loadingTable"
        :scroll="{ x: 1500, y: 300 }"
        bordered
      >
        <div class="d-flex flex-column" slot="nama" slot-scope="value, record">
          <span class="mb-1 font-weight-bold">{{record.guru.nik}}</span>
          <span class="mb-1 font-weight-bold">{{value}}</span>
          <span class="mb-3">({{moment(record.createdAt).format('DD MMM YYYY HH:mm')}})</span>
          <span>
            <a-tag :color="record.status_pengajuan === 'menunggu' ? 'orange' : record.status_pengajuan === 'tolak' ? 'red' : 'green'">
            {{record.status_pengajuan === 'terima' ? 'DITERIMA' : record.status_pengajuan.toUpperCase()}}
          </a-tag>
          </span>
        </div>
        <div slot="regulasi" slot-scope="value">
          <a-tag :color="value === 'Tidak Dibayar' ? 'red' : 'green'">
            {{value.toUpperCase()}}
          </a-tag>
        </div>
        <div class="d-flex flex-column" slot="aksi" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManagePengajuanIzin('edit', record)"
            class="mr-3 text-warning border border-warning w-100"
            size="default"
          >
            <a-icon type="idcard"/>Detail
          </a-button>
          <a-button
            @click.prevent="handleApprovalPengajuanIzin('approve', record)"
            class="mr-3 text-info border border-info w-100 my-3"
            :disabled="record.status_pengajuan === 'terima'"
            size="default"
          >
            <a-icon type="check"/>{{record.status_pengajuan === 'terima' ? 'Disetujui' : 'Setuju'}}
          </a-button>
          <a-button
            @click.prevent="toggleModalManageKeteranganAdminPengajuanIzin('edit', record)"
            class="text-danger border border-danger w-100"
            size="default"
          >
            <a-icon type="close" />{{record.status_pengajuan === 'terima' ? 'Batalkan' : 'Tolak'}}
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const FormPengajuanIzin = () => import('@/components/app/AdminHR/Izin/FormPengajuanIzin')
const pengajuanIzinColumns = [
  {
    title: 'Pemohon',
    dataIndex: 'nama',
    key: 'nama',
    width: 270,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Izin',
    dataIndex: 'nama_izin',
    key: 'nama_izin',
    scopedSlots: { customRender: 'nama_izin' },
  },
  {
    title: 'Regulasi',
    dataIndex: 'regulasi',
    key: 'regulasi',
    align: 'center',
    scopedSlots: { customRender: 'regulasi' },
  },
  {
    title: 'Periode',
    dataIndex: 'periode',
    key: 'periode',
    align: 'center',
    scopedSlots: { customRender: 'periode' },
    children: [
      {
        title: 'Mulai',
        dataIndex: 'tanggal_awal',
        key: 'tanggal_awal',
        align: 'center',
        scopedSlots: { customRender: 'tanggal_awal' },
      },
      {
        title: 'Selesai',
        dataIndex: 'tanggal_akhir',
        key: 'tanggal_akhir',
        align: 'center',
        scopedSlots: { customRender: 'tanggal_akhir' },
      },
    ],
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  components: {
    FormPengajuanIzin,
  },
  data() {
    return {
      pengajuanIzinColumns,
      pengajuanIzinDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManagePengajuanIzin: false,
      visibleModalManageKeteranganAdminPengajuanIzin: false,
      loadingActionModalManagePengajuanIzin: false,
      loadingActionModalManageKeteranganAdminPengajuanIzin: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      statusKeteranganAdmin: {
        isEdit: false,
        isCreate: false,
      },
      newPengajuanIzin: {
        id_izin: null,
        id_pegawai: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        keterangan_tambahan: null,
        keterangan_admin: null,
        files: null,
        izin: null,
        guru: null,
        createdAt: null,
      },
      idEdit: null,
    }
  },
  methods: {
    moment,
    handleChange(payload) {
      const { value, column } = payload

      this.newPengajuanIzin[column] = value
    },
    toggleModalManageKeteranganAdminPengajuanIzin(status, data) {
      this.visibleModalManageKeteranganAdminPengajuanIzin = !this.visibleModalManageKeteranganAdminPengajuanIzin
      if (!this.visibleModalManageKeteranganAdminPengajuanIzin) {
        setTimeout(() => {
          this.statusKeteranganAdmin = {
            isEdit: false,
            isCreate: false,
          }
          this.newPengajuanIzin = {
            id_izin: null,
            id_pegawai: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            keterangan_tambahan: null,
            keterangan_admin: null,
            files: null,
            izin: null,
            guru: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPengajuanIzin = {
            id_izin: data.id_izin,
            id_pegawai: data.id_pegawai,
            tanggal_awal: data.tanggal_awal,
            tanggal_akhir: data.tanggal_akhir,
            keterangan_tambahan: data.keterangan_tambahan,
            keterangan_admin: data.keterangan_admin,
            files: data.files,
            izin: data.izin,
            guru: data.guru,
          }
        }
      }
    },
    toggleModalManagePengajuanIzin(status, data) {
      this.visibleModalManagePengajuanIzin = !this.visibleModalManagePengajuanIzin
      if (!this.visibleModalManagePengajuanIzin) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newPengajuanIzin = {
            id_izin: null,
            id_pegawai: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            keterangan_tambahan: null,
            keterangan_admin: null,
            files: null,
            izin: null,
            guru: null,
            createdAt: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPengajuanIzin = {
            id_izin: data.id_izin,
            id_pegawai: data.id_pegawai,
            tanggal_awal: data.tanggal_awal,
            tanggal_akhir: data.tanggal_akhir,
            keterangan_tambahan: data.keterangan_tambahan,
            keterangan_admin: data.keterangan_admin,
            files: data.files,
            izin: data.izin,
            guru: data.guru,
            createdAt: data.createdAt,
          }
        }
      }
    },
    handleOkModalManagePengajuanIzin() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah izin ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat izin baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManagePengajuanIzin = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataIzin: this.newPengajuanIzin,
            } : {
              dataIzin: this.newPengajuanIzin,
            }
            this.$store.dispatch(`izin/${this.status.isEdit ? 'EDIT' : 'POST'}_IZIN`, payload)
              .then(isSuccess => {
                this.loadingActionModalManagePengajuanIzin = false
                this.toggleModalManagePengajuanIzin()
                this.fetchDataPengajuanIzin()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Izin berhasil diubah' : 'Izin baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Izin gagal diubah' : 'Izin baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleApprovalPengajuanIzin(status, data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin {status === 'approve' ? 'menerima' : 'menolak'} izin <b>{data.nama}</b> ?</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingActionModalManageKeteranganAdminPengajuanIzin = true
          this.$store.dispatch(`izin/${status === 'approve' ? 'APPROVE' : 'REJECT'}_PENGAJUAN_IZIN`, {
            id: status === 'approve' ? data.key : this.idEdit,
            dataPengajuanIzin: this.newPengajuanIzin,
          })
            .then(isSuccess => {
              this.loadingActionModalManageKeteranganAdminPengajuanIzin = false
              if (status === 'reject') this.toggleModalManageKeteranganAdminPengajuanIzin()
              this.fetchDataPengajuanIzin()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    `Pengajuan berhasil ${status === 'approve' ? 'diterima' : 'ditolak'}`,
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: `Pengajuan gagal ${status === 'approve' ? 'diterima' : 'ditolak'}`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: status === 'approve' ? 'primary' : 'danger',
        okText: status === 'approve' ? 'Terima Persetujuan Izin' : 'Tolak Persetujuan Izin',
        cancelText: 'Batal',
      })
    },
    fetchDataPengajuanIzin(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('izin/FETCH_PENGAJUAN_IZIN', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          this.pengajuanIzinDataTable = res.map(el => {
            return {
              key: el.id,
              id: el.id,
              ...el,
              nama: el.guru.nama,
              nama_izin: el.izin.nama,
              regulasi: el.izin.regulasi,
              tanggal_awal: moment(el.tanggal_awal).format('DD MMM YYYY'),
              tanggal_akhir: moment(el.tanggal_akhir).format('DD MMM YYYY'),
              tanggal_mengajukan: moment(el.tanggal_akhir).format('DD MMM YYYY'),
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataPengajuanIzin()
  },
  computed: {
    isValidateForm() {
      if (this.newPengajuanIzin.nama && this.newPengajuanIzin.tipe && this.newPengajuanIzin.regulasi) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
